// @flow

export const persistValueBetweenEntries = <Value>(
    generateValue: (args: any) => Value,
    persistOnServer: boolean = false
) => {
    let persistedValue: Value;

    return (...args: any): Value => {
        if (process.env.WEBPACK_TARGET === 'web' || persistOnServer) {
            if (!persistedValue) {
                persistedValue = generateValue(...args);
            }
            return persistedValue;
        }
        return generateValue(...args);
    };
};
