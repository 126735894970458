// @flow

type QueryCacheKey =
    | 'BuyResidentialListingDetails'
    | 'RentResidentialListingDetails'
    | 'SoldResidentialListingDetails'
    | 'ProjectProfileDetails';

type CreateDetailsResolver = (
    projectProfileChildListingIds: Map<string, Array<string>>
) => (
    parent: { details: {} },
    args: { id: string },
    cache: {},
    info: { variables: { queryCacheKey?: QueryCacheKey } }
) => {};

export const createDetailsResolver: CreateDetailsResolver =
    (projectProfileChildListingIds) => (parent, args, _, info) => {
        if (info.variables.queryCacheKey === 'RentResidentialListingDetails') {
            return {
                __typename: 'RentResidentialListingDetails',
                listing: {
                    __typename: 'RentResidentialListing',
                    id: args.id,
                },
            };
        } else if (info.variables.queryCacheKey === 'ProjectProfileDetails') {
            return {
                __typename: 'ProjectProfileDetails',
                listing: {
                    __typename: 'ProjectProfile',
                    id: args.id,
                    childListings: {
                        __typename: 'ChildListings',
                        results: (projectProfileChildListingIds.get(args.id) ?? []).map((id) => ({
                            __typename: 'BuyResidentialListing',
                            id,
                        })),
                    },
                },
            };
        } else if (info.variables.queryCacheKey === 'BuyResidentialListingDetails') {
            return {
                __typename: 'BuyResidentialListingDetails',
                listing: {
                    __typename: 'BuyResidentialListing',
                    id: args.id,
                },
            };
        } else if (info.variables.queryCacheKey === 'SoldResidentialListingDetails') {
            return {
                __typename: 'SoldResidentialListingDetails',
                listing: {
                    __typename: 'SoldResidentialListing',
                    id: args.id,
                },
            };
        }
        return parent.details;
    };
