/* @flow */
import { logger as argonautLogger } from '@rea-argonaut/observability';

export type NewRelicCustomAttribute = {
    [string]: string | number,
};

export type AppLogger = {
    +error: (message: string, customAttributes?: NewRelicCustomAttribute) => (error?: Error) => void,
    +warn: (...args: Array<*>) => void,
    +log: (...args: Array<*>) => void,
};

export const createLogger = () => {
    return {
        error: (message: string, customAttributes?: NewRelicCustomAttribute) => (error?: Error) => {
            argonautLogger.error({ error, description: message, ...(customAttributes ? { customAttributes } : {}) });
        },
        warn: (...args: Array<*>) => {
            argonautLogger.log(args.join(' - '));
        },
        log: (...args: Array<*>) => {
            argonautLogger.log(args.join(' - '));
        },
    };
};

export default createLogger();
