// @flow
import { createClient as createUrqlClient, dedupExchange, fetchExchange } from '@urql/core';
import { cacheExchange } from '@urql/exchange-graphcache';
import { createFetch } from '@rea-argonaut/fetch';
import { devtoolsExchange } from '@urql/devtools';
import { persistedFetchExchange } from '@urql/exchange-persisted-fetch';

import { getLexaHostClient, getLexaHostServer, IS_SERVER, IS_CLIENT } from '../config';

import type { UrqlClient, SsrExchange } from './types';
import { persistValueBetweenEntries } from '../util/persistValueBetweenEntries';
import introspectedSchema from '../../../tmp/lexa-schema.json';
import { createDetailsResolver } from './createDetailsResolver';
import { createCacheKeyMap } from './createCacheKeyMap';
import { typenameExchange } from './typenameExchange';

export type GetClient = ({| ssrExchange: SsrExchange |}) => UrqlClient;

export const createClient: GetClient = ({ ssrExchange }) => {
    const projectProfileChildListingIds = new Map();

    const cache = IS_SERVER
        ? typenameExchange()
        : cacheExchange({
              schema: introspectedSchema,
              keys: createCacheKeyMap(projectProfileChildListingIds),
              resolvers: {
                  Query: {
                      detailsV2: createDetailsResolver(projectProfileChildListingIds),
                  },
              },
          });

    const host = IS_SERVER ? getLexaHostServer() : getLexaHostClient();

    const loadDevtoolsIfEnabled = () => {
        /**
         * NB: the urql devtools contain a hardcoded NODE_ENV === production check, so
         * checking === 'local' is only added here to make behaviour more clear.
         * For more details see README.
         */
        const enableDevtools = IS_CLIENT && ARGONAUT_ENV === 'local';

        if (!enableDevtools) {
            return [];
        }

        return [devtoolsExchange];
    };

    const exchanges = [
        ...loadDevtoolsIfEnabled(),
        dedupExchange,
        cache,
        ssrExchange,
        persistedFetchExchange({
            preferGetForPersistedQueries: false,
        }),
        fetchExchange,
    ];

    const client = createUrqlClient({
        url: `${host}/graphql`,
        fetch: createFetch({}),
        exchanges,
    });

    return client;
};

const persistedCreateClient: GetClient = (persistValueBetweenEntries<UrqlClient>(createClient): any);

export default persistedCreateClient;
