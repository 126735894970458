// @flow

import {
    type BuyResidentialListing,
    type RentResidentialListing,
    type SoldResidentialListing,
    type ProjectProfile,
} from '../models/lexa';

export const createCacheKeyMap = (
    projectProfileChildListingIds: Map<string, Array<string>>
): ({ [key: string]: (data: any) => string | null }) => {
    const cacheKeyMap = new Proxy(
        {
            BuyResidentialListing: (data: BuyResidentialListing) => data.id,
            RentResidentialListing: (data: RentResidentialListing) => data.id,
            SoldResidentialListing: (data: SoldResidentialListing) => data.id,
            ProjectProfile: (data: ProjectProfile) => {
                if (data.childListings && data.childListings.results.length > 0) {
                    const childListingIds = data.childListings.results.map((listing) => listing.id);
                    const key = `${data.id}__${childListingIds.join('-')}`;
                    if (!projectProfileChildListingIds.has(data.id) || data.belowTheFoldId) {
                        projectProfileChildListingIds.set(data.id, childListingIds);
                    }
                    return key;
                }
                return null;
            },
        },
        {
            get(target, prop) {
                if (prop in target) {
                    return target[prop];
                }
                return () => null;
            },
        }
    );

    return cacheKeyMap;
};
