import { formatDocument as formatDocumentDefault, makeOperation } from '@urql/core';
import { map, pipe } from 'wonka';

export const typenameExchange =
    (formatDocument = formatDocumentDefault) =>
    ({ forward }) => {
        const prepareForwardedOperation = (operation) => {
            return makeOperation(
                operation.kind,
                {
                    key: operation.key,
                    query: formatDocument(operation.query),
                    variables: operation.variables,
                },
                operation.context
            );
        };

        return (ops$) => {
            return pipe(ops$, map(prepareForwardedOperation), forward);
        };
    };
