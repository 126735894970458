// @flow
import { type UrqlClient, type QueryResult, type RequestPolicy, type FetchOptions } from './urql/types';

export type FetchData = (args: {
    client: UrqlClient,
    query: {},
    queryVariables: {},
    hasData: (result: QueryResult<any>) => boolean,
    requestPolicy?: RequestPolicy,
    fetchOptions?: FetchOptions,
}) => Promise<any>;

const overrideRequestPolicyInNode = (requestPolicy) => {
    if (process.env.WEBPACK_TARGET === 'node') {
        return 'network-only';
    }
    return requestPolicy;
};

const fetchData: FetchData = async ({ client, query, queryVariables, hasData, requestPolicy, fetchOptions = {} }) => {
    const result: QueryResult<any> = await client
        .query(query, queryVariables, { requestPolicy: overrideRequestPolicyInNode(requestPolicy), fetchOptions })
        .toPromise();

    if (hasData(result)) {
        return result.data;
    }

    if (result.error) {
        if (result.error.networkError) {
            throw new Error(`GraphQL Network Error: ${result.error.networkError.message}`);
        }

        if (result.error.graphQLErrors) {
            throw new Error(`LEXA GraphQL Error: ${JSON.stringify(result.error.graphQLErrors)}`);
        }

        throw result.error;
    }
};

export default fetchData;
