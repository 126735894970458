// @flow
import { ssrExchange as createUrqlSsrExchange } from '@urql/core';
import { read } from '@rea-argonaut/exchange';
import { IS_CLIENT } from '../config';

import { type SsrExchange } from './types';

export const SSR_CACHE_KEY = 'urqlClientCache';

type CreateSsrExchangeArgs = {|
    createSsrExchange?: ({ isClient: boolean, initialState: any }) => SsrExchange,
    readFromExchange?: ArgonautExchangeRead,
|};

type CreateSsrExchange = (args?: CreateSsrExchangeArgs) => Promise<SsrExchange>;

const createSsrExchange: CreateSsrExchange = async (args = {}) => {
    const { createSsrExchange = createUrqlSsrExchange, readFromExchange = read } = args;

    const initialState = (await readFromExchange(SSR_CACHE_KEY)) || undefined;

    return createSsrExchange({
        isClient: IS_CLIENT,
        initialState,
    });
};

export default createSsrExchange;
