/* @flow */
import { getEnvVar } from '@rea-argonaut/env';

export const DEBUG = false;
export const NODE_ENV = process.env.NODE_ENV || 'production';
export const IS_DEVELOPMENT = NODE_ENV === 'development';
export const IS_PRODUCTION = NODE_ENV === 'production';
export const LISTING_SERVICES_HOST = 'https://services.realestate.com.au';
export const LISTING_SERVICES_CLIENT = 'services.realestate.com.au';
export const SAVED_SEARCH_API_HOST = 'https://saved-search-api.realestate.com.au';
export const REISSUE_TOKEN_ENDPOINT = 'https://www.realestate.com.au/login/reissue';
export const MYREA_LOGIN_ENDPOINT =
    'https://www.realestate.com.au/my-real-estate/login?continueUrl=https%3A%2F%2Fwww.realestate.com.au%2Fmy-real-estate%2Fpost-tab-auth';
export const MYREA_SIGNUP_ENDPOINT =
    'https://www.realestate.com.au/my-real-estate/register?continueUrl=https%3A%2F%2Fwww.realestate.com.au%2Fmy-real-estate%2Fpost-tab-auth';
export const MYREA_AUTH_CALLBACK_ORIGIN = 'https://www.realestate.com.au';

export const PRODUCTION_DESKTOP_HOST = 'www.realestate.com.au';
export const PRODUCTION_MSITE_HOST = 'm.realestate.com.au';
export const GOOGLE_MAPS_CLIENT_ID = 'gme-nsp';

export const IS_CLIENT = process.env.WEBPACK_TARGET === 'web';
export const IS_SERVER = process.env.WEBPACK_TARGET === 'node';

export const LEXA_HOST_SERVER_DEFAULT = 'https://api.lexa.resi-property.realestate.com.au';
export const LEXA_HOST_CLIENT_DEFAULT = 'https://lexa.realestate.com.au';
export const LEXA_HOST = 'lexa.realestate.com.au';
export const COLLEXA_HOST = 'collections-api.realestate.com.au';
export const COLLEXA_HOST_CLIENT = 'https://collections-api.realestate.com.au';
export const AGENT_CONTACT_HOST = 'agent-contact.realestate.com.au';
export const AGENT_CONTACT_HOST_CLIENT = 'https://agent-contact.realestate.com.au';

export const getLexaHostClient = () => getEnvVar<string>('LEXA_HOST_CLIENT') || LEXA_HOST_CLIENT_DEFAULT;
export const getLexaHostServer = () => getEnvVar<string>('LEXA_HOST_SERVER') || LEXA_HOST_SERVER_DEFAULT;
